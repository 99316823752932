.gpt-container {
  width: 800px;
  margin: 0 auto;
  text-align: center;
  margin-top: 50px;
  background-color: #222;
  border-radius: 20px;
  padding: 20px; /* Uniform padding for better text wrapping */
  color: white;
  box-shadow: 26px 28px 26px rgba(0, 0, 0, 0.8);
  overflow: hidden; /* Ensure no content overflows horizontally */
}

.gpt-input-area {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 100%; /* Set width of the input area */
  color: white;
}

textarea {
  width: 100%;
  height: 40px;
  resize: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 2px solid #222;
  line-height: 20px; /* Vertically centers the text inside the text area */
  background-color: #333;
  color: white;
}

h1 {
  color: white;
}

button3 {
  position: absolute;
  right: 10px; /* Align button to the right inside the text box */
  top: 50%;
  transform: translateY(-50%); /* Vertically center the button */
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #057aff;
  color: white;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: center; /* Horizontally centers the icon */
  align-items: center; /* Vertically centers the icon */
  padding: 0 9px;
}

button3:hover {
  background-color: #0061cf;
}

.gpt-conversation {
  margin-top: 20px;
  text-align: left;
  overflow-wrap: break-word; /* Ensure long words break */
  word-wrap: break-word; /* Adds compatibility for older browsers */
  word-break: break-word;
}

.message-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.message-header .timestamp {
  font-size: 12px;
  color: #057aff;
}

.message-header .sender {
  font-size: 14px;
  font-weight: bold;
  color: #057aff;
  margin-right: 5px;
}

.message {
  margin-bottom: 15px;
}

.message-timestamp {
  font-size: 18px;
  color: #057aff;
}

.message-content {
  font-size: 17px;
  color: #ffffff;
  line-height: 1.5; /* Improves readability */
  white-space: normal; /* Forces wrapping of text */
  word-break: break-word; /* Ensures long words break properly */
  overflow-wrap: break-word; /* Compatibility for breaking words */
  overflow: hidden; /* Prevents horizontal overflow */
  text-align: left; /* Align text for better readability */
}

.gpt .message-content {
  color: #ffffff;
}

.user .message-content {
  color: #ffffff;
}

.typewriter-effect {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
  animation: typing 2s steps(30) 1s forwards;
}

.spinner img {
  width: 250px;
}

@keyframes typing {
  to {
      width: 100%;
  }
}

/* Mobile view fixes */
@media (max-width: 768px) {
  .gpt-container {
      width: 90%;
      max-width: 350px; /* Prevent container from growing */
      padding: 15px; /* Reduce padding for better wrapping */
      font-size: 14px;
      overflow: hidden; /* Ensure no horizontal overflow */
  }

  textarea {
      font-size: 14px;
      line-height: 18px; /* Adjust text area spacing */
  }

  h1 {
      font-size: 20px;
  }

  .message-content {
      font-size: 14px; /* Smaller font size for mobile */
      line-height: 1.4; /* Compact line height */
      white-space: normal; /* Wrap text to the next line */
      overflow-wrap: break-word; /* Ensure text breaks inside container */
  }

  .message-header .timestamp,
  .message-header .sender {
      font-size: 12px;
  }
}
