/* Navbar container */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: #222;
  color: #ffffff;
  height: 50px;
  position: relative;
  border-bottom: 2px solid #007bff;
}

.logo {
  height: 60px;
}

.navbar-title { 
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  display: inline-block; /* Change block to inline-block */
  margin: 0 auto; /* Center the element horizontally */
  background-color: #007bff;
  border-radius: 20px;
  padding: 8px 10px;
}

.hamburger-menu {
  display: block;
  font-size: 2rem;
  cursor: pointer;
  color: #ffffff;
  border: none;
  background: none;
  z-index: 1000;
}

.hamburger-menu:hover {
  color: #007bff;
  transform: scale(1.3);
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 60px;
  right: 8px;
  width: 150px;
  padding: 2px;
  border-radius: 20px;
  z-index: 999;
  border: #ffffff 3px solid;
}

.dropdown-menu.open {
  display: block;
  background-color: #222;
}

.dropdown-menu a {
  display: block;
  color: #ffffff;
  text-decoration: none;
  font-weight: 600;
  padding: 10px;
}

.dropdown-menu a:hover {
  background-color: #007bff;
  border-radius: 20px;
  opacity: 0.9;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .navbar-title {
      font-size: 1rem;
  }

  .navbar-title {
      display: none;
  }

  /* Reduce dropdown menu size on mobile screens */
  .dropdown-menu {
      width: 120px; /* Adjust width to make dropdown narrower */
  }

  .dropdown-menu a {
      font-size: 0.9rem; /* Smaller font size */
      padding: 6px; /* Reduce padding for dropdown options */
  }
}

@media (min-width: 769px) {
  .menu {
      display: flex;
      justify-content: flex-end;
  }

  .menu button {
      background: none;
      border: 1px solid white;
      color: white;
      padding: 5px 5px;
      cursor: pointer;
      margin-left: 10px;
  }

  .dropdown-menu {
      display: none;
  }
}
