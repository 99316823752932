.community-container1 {
  background-color: transparent;
  text-align: center;
}

.community-container1 h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #ffffff;
  text-align: left;
}

.coin-logos1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 18px;
}

.coin-logo-item1 {
  padding: 15px;
  width: 110px;
  background-color: #222;
  border-radius: 8px;
  position: relative; /* Enable absolute positioning for rank */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}


.coin-logo-item1:hover {
  transform: translateY(-15px);
  box-shadow: 10px 8px 16px #ffffff;
}

.coin-logo1 {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-bottom: 10px;
  border: #007bff solid 3px;
  border-radius: 100px;
}

.coin-name1 {
  font-size: 0.95rem;
  font-weight: 500;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 5px;
}

/* Style the rank number and position it in the top-right */
.coin-rank1 {
  position: absolute;
  top: 8px;
  right: 4px;
  font-size: 1.2rem;
  color: #ffffff; /* Gold color for the rank number */
  background-color: #007bff; /* Slightly transparent background */
  padding: 0px 10px;
  border-radius: 12px;
  font-weight: 500;
}

.trophy-button {
  width: 20px;
  height: 20px;
}

/* Style the rank number and position it in the top-right */
.coin-votes {
  position: absolute;
  top: 38px;
  right: 4px;
  font-size: .8rem;
  font-weight: 500;
  color: #ffffff; /* Gold color for the rank number */
  background-color: #000000; /* Slightly transparent background */
  padding: 2px 6px;
  border-radius: 12px;
}

.social-icons {
  display: flex;
  gap: 8px;
  width: 100%;
}

.social-icon {
  width: 20px;
  height: 20px;
}

.social-icon:hover {
  transform: scale(1.2);
}

@media (max-width: 768px) {
  .coin-logos1 {
    justify-content: space-around;
  }

  .coin-logo-item1 {
    width: 100px;
    padding: 20px;
  }

  .coin-logo1 {
    width: 50px;
    height: 50px;
  }

  .coin-name1 {
    font-size: 0.85rem;
  }

  .community-container1 h1 {
    font-size: 1.6rem;
  }

  .coin-rank1 {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 1.2rem;
    color: #ffffff; /* Gold color for the rank number */
    background-color: #007bff; /* Slightly transparent background */
    padding: 0px 10px;
    border-radius: 12px;
    font-weight: 500;
  }

  .coin-votes {
    position: absolute;
    top: 40px;
    right: 8px;
    font-size: .8rem;
    font-weight: 500;
    color: #ffffff; /* Gold color for the rank number */
    background-color: #000000; /* Slightly transparent background */
    padding: 2px 6px;
    border-radius: 12px;
  }
}
