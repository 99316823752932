.learn-more {
  margin-top: 3%;
  padding: 20px;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #222;
  border-radius: 20px;
}

.sliding-image-container {
  position: relative;
  width: 100%;
  height: 100px; /* Adjust height as needed */
  overflow: hidden;
}

.sliding-image {
  position: absolute;
  top: 0;
  left: -200px;
  width: 300px;
  height: 100px; /* Keep height auto to maintain aspect ratio */
  aspect-ratio: 1 / 1; /* Ensures a square aspect ratio */
  animation: slideAndSpin 8s linear infinite;
}

@keyframes slideAndSpin {
  0% {
      transform: translateX(-200px);
  }
  100% {
      transform: translateX(100vw);
  }
}

.text-content {
  text-align: left;
  color: white;
  font-size: 18px;
  font-weight: 600;
  padding: 0 50px 0 40px;
}

.text-content p:last-child {
  color: red;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .sliding-image {
      width: 100px;
      height: auto; /* Maintain aspect ratio */
  }

  .text-content p {
      font-size: 14px;
  }
  .learn-more {
    margin-top: 50px;
  }
  .text-content {
    text-align: left;
    color: white;
    font-size: 18px;
    font-weight: 600;
    padding: 0 10px 0 10px;
  }
}

/* Laptop responsiveness */
@media (max-width: 1024px) {
  .sliding-image {
      width: 200px;
      height: 100px; /* Maintain aspect ratio */
  }

  .text-content p {
      font-size: 15px;
  }
}
