.app {
  display: flex;
  flex-direction: column;
  min-height: 120vh;
  background-color: #007bff;
}

.main-content {
  display: flex;
  flex: 1;
}

.sidebar {
  width: 250px;
  flex-shrink: 0;
  min-height: 100vh;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
