.featured-container {
    text-align: center;
    padding: 20px;
  }

  .sliding-image-container1 {
    position: relative;
    width: 100%;
    height: 150px; /* Adjust height as needed */
    overflow: hidden;
    top: 50px;
  }
  
  .sliding-image1 {
    position: absolute;
    top: 0;
    left: -200px;
    width: 400px;
    height: 100px; /* Keep height auto to maintain aspect ratio */
    animation: slideAndSpin 8s linear infinite;
  }
  
  @keyframes slideAndSpin1 {
    0% {
        transform: translateX(-200px) rotate(0deg);
    }
    100% {
        transform: translateX(100vw) rotate(0deg);
    }
  }
  .featured-container h1 {
    margin-top: 30px;
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
    color: white;
  }
  
  .featured-cards {
    display: flex;
    justify-content: center;
    gap: 25px;
    flex-wrap: wrap;
  }
  
  .featured-card {
    background-color: #222;
    width: 180px;
    height: 260px;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    transition: transform 0.3s;
    border: rgb(0, 0, 0) 3px solid;
  }
  
  .featured-card:hover {
    transform: translateY(-18px);
    box-shadow: 12px 14px 12px rgba(255, 115, 0, 0.8);
  }
  
  .featured-logo {
    width: 90px;
    height: 90px;
    margin: 0 auto 10px; /* Centers horizontally and adds bottom margin */
    object-fit: contain;
    border: #007bff solid 3px;
    border-radius: 100px;
  }
  
  
  .featured-name {
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
    margin-bottom: -15px;
  }
  
  .featured-symbol {
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
    margin-bottom: -15px;
  }
  
  .featured-24change {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: white;
  }
  
  .social-icons1 {
    display: flex;
    justify-content: center;
    gap: 8px;
  }
  
  .social-icon1 {
    width: 30px;
    height: 30px;
    object-fit: contain;
    transition: transform 0.3s;
  }
  
  .social-icon1:hover {
    transform: scale(1.2);
  }
  
  /* Mobile-specific adjustments */
  @media (max-width: 600px) {
    .featured-container h1 {
      font-size: 1.2rem;
      margin-top: 20px;
      margin-bottom: 15px;
    }
  
    .featured-card {
      width: 150px;
      height: 240px;
      padding: 8px;
    }
  
    .featured-logo {
      width: 60px;
      height: 60px;
      margin-bottom: 6px;
      border-width: 3px;
    }
  
    .featured-name,
    .featured-symbol,
    .featured-24change {
      font-size: 1rem;
    }
  
    .social-icon1 {
      width: 25px;
      height: 25px;
    }
  }
  
