/* PromoBox Styling */
.promo-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem;
    background-color: #222;
    color: rgb(255, 255, 255);
    border-radius: 30px;
    height: 175px; /* Ensure there’s a height for the vertical divider */
    box-shadow: 16px 18px 16px rgba(0, 0, 0, 0.8);

  }
  
  .promo-logo {
    margin-right: 1rem;
    height: 150px;
  }
  
  .promo-divider {
    width: 5px;
    height: 90%; /* 80% of the height of the promo-box */
    background-color: #007bff; /* Blue color for the divider */
    margin: 0 3rem; /* Add space around the divider */
  }
  
  .promo-content {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Ensure the text is centered vertically */
  }
  
  .promo-content p {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
    padding-right: 100px;
  }
  
  .promo-buttons {
    display: flex;
    gap: 10px;
  }
  
  .promo-buttons button {
    padding: 10px 20px;
    background-color: #007bff; /* Blue background */
    border: none;
    color: white;
    border-radius: 20px; /* Rounded corners */
    font-weight: 700; /* Bold text */
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 20px;
  }
  
  .promo-buttons button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }


  @media (max-width: 1400px) {
    .promo-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 2rem;
        background-color: #222;
        color: white;
        border-radius: 20px;
        height: 175px; /* Ensure there’s a height for the vertical divider */
      }
  
    .promo-divider {
      display: none; /* Optionally hide the divider on smaller screens */
    }

    .promo-content p {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 15px;
        padding-right: 10px;
      }
  }

  
  @media (max-width: 768px) {
    .promo-box {
      flex-direction: column; /* Stack the content on mobile */
      align-items: center;
      background-color: #222;
    }
  
    .promo-divider {
      display: none; /* Optionally hide the divider on smaller screens */
    }

    .promo-content p {
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 8px;
        padding-right: 1px;
        text-align: center;
      }

    .promo-logo {
        height: 65px;
    }

    .promo-buttons {
        display: flex;
        gap: 10px;
    }
  }
  
